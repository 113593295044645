import { Component, ElementRef, ViewChild } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-action-center',
    templateUrl: './action-center.component.html',
    styleUrls: ['./action-center.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class ActionCenterComponent {
    @ViewChild("dialog") dialogRef: ElementRef;

    alertCount = 0;

    constructor(
        private toaster: ToasterService,
        public user: UserService,
        private dialog: DialogService,
        private notifications: NotificationService
    ) {
        notifications.subscribe(n => {
            if (!n) return;
            // const overdue = n.owner.concat(n.responsible).filter(e => {
            //     const endTime = new Date(e.endDate).getTime();
            //     if (endTime < Date.now())
            //         return true;
            //     return false;
            // });

            this.alertCount = n.responsible.length;
        });
    }

    openDialog() {
        const titleItem = document.querySelector(".title-item");

        // If the dialog is already open, close it.
        if (document.querySelector('.dialog-nav-actionCenter')) {
            titleItem.classList.remove("active");
            window['__actionCenter']?.dialog?.close();
            return;
        };

        titleItem.classList.add("active");

        this.dialog.open("nav-actionCenter", {
            inputs: {
                ...this.notifications.value
            },
            closeOnNavigation: false,
            restoreFocus: true,
            panelClass: "dialog-actionCenter",
            isDark: true,
            isModal: false,
            hasBackdrop: false,
            position: {
                top: "42px",
                right: "200px"
            }
        }).then(() => {
            titleItem.classList.remove("active");
        });
    }
}
