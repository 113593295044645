@if (isAutocompleteVisible) {
    <div class="exit" (click)="onClose()"></div>
}

<mat-progress-bar
    mode="indeterminate"
    [style.top]="loadingTenant ? '0px' : '-2px'"
/>

<div #auto class="auto" [class.active]="isAutocompleteVisible" [class.presenting]="isPresenting">
    <div class="label">
        <span class="tenantName">{{tenant.value.account_name}}</span>
        <span class="tenantId"> ({{tenant.value.es_tenant_name}})</span>
    </div>

    @if (user.isDynatraceUser) {
        <button class="lock" (click)="onPresentationModeClick()">
            <img [src]="'assets/img/ui-controls/presenting-' + (isPresenting ? 'lock.png' : 'unlock.png')" />
        </button>
    }

    <span class="input">
        <input #autocomplete
            placeholder="Filter"
            [value]="tenantFilter"
            (change)="tenantFilter = autocomplete.value"
            (focus)="isAutocompleteVisible = true"
            (keyup)="filterTenants(autocomplete.value)"
            (keypress)="filterTenantKeyPress($event)"
        />
    </span>
    <div
        class="items"
        [style.height]="
            (isPresenting
                ? (clientItemHeight*(demoTenants.length+1)) + 20
                : (clientItemHeight*filteredTenants.length) + 20
            )
            + 'px'
        "
    >
        @if (isPresenting) {
            @if (presentedTenant) {
                <div
                    [attr.data-dtname]="'Change tenant ' + presentedTenant.es_tenant_name"
                    (click)="onClientSelect(presentedTenant)"
                >
                    <span class="tenantName">
                        {{presentedTenant.account_name}}
                    </span>
                    <span class="tenantId">
                        {{presentedTenant.es_tenant_name}}
                    </span>
                </div>
            }
            @for (tenant of demoTenants; track tenant) {
                <div
                    [attr.data-dtname]="'Change tenant ' + tenant.es_tenant_name"
                    (click)="onClientSelect(tenant)"
                >
                    <span class="tenantName">
                        {{tenant.account_name}}
                    </span>
                    <span class="tenantId">
                        {{tenant.es_tenant_name}}
                    </span>
                </div>
            }
        }
        @else {
            @for (t of filteredTenants; track t) {
                <div
                    [class.selected]="t == tenant.value"
                    [attr.data-dtname]="'Change tenant ' + t.es_tenant_name"
                    (click)="onClientSelect(t)"
                >
                    <span class="tenantName">
                        {{t.account_name}}
                    </span>
                    <span class="tenantId">
                        {{t.es_tenant_name}}
                    </span>
                </div>
            }
        }
    </div>
</div>
