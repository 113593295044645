/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';
import { OrganizationType } from './organizationtype';

export interface Company_v1 extends DTO, DTOMethods<Company_v1> {
    orgType: OrganizationType;
    owner: DTORef;
    children: DTORef[];
    resolvedUsers: Attachment[];
}
export const Company_v1: DTOMetaData & DTOClassMethods<Company_v1> = {
    _label:'Companies',
    _dto: 'Company_v1',
    _type: 'company',
    _colors: { primaryThemeColor: "#c2ac19", secondaryThemeColor: "#c2ac19", tertiaryThemeColor: "#c2ac19" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'IDX (External Identifier)', prop: 'idx', hint: 'ID of this organization', type: 'string', fixed: true, source: null, values: undefined },
        { label: 'Type', prop: 'orgType', hint: 'Organization type', type: 'enum', fixed: false, source: undefined, values: ["COMPANY", "DIVISION", "DEPARTMENT", "TEAM"] },
        { label: 'Owner', prop: 'owner', hint: 'User', type: 'dto', fixed: true, source: '/user/v1.0', values: undefined },
     ],
    _related: [
        { prop: 'children', type: 'organization', class: 'Organization_v1', label: 'Organizations' },
        { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'Users' },
        { prop: 'assignments', type: 'unknown', class: 'Unknown', label: 'Assignments' },
     ],
    _endpoint: '/api/eom/v1.0/company/v1.0',
    _docLink: '/wiki/elevate/Assets/organization/',
    _icon: './assets/dtos/company.svg',
    _childrenAt: 'children',
    endpointAPI: new BoundEndpointAPI<Company_v1>('Company_v1', DTO),
    from: (obj: any): Company_v1 => {
        return Object.setPrototypeOf(obj, Company_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Company_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Company_v1.from(a) as Company_v1)
            : Company_v1.from(res)
    )}
}
