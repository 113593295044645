
// This registry contains standalone builds of large Syncfusion components.

import { ComponentRegistration } from 'src/app/components/lazy-loader/types';

// Use to optimize page loading where meaningful.
export const ApiTools: ComponentRegistration[] = [
    // Api Tools
    { id: 'DtApiConfigManager',   group: "apitools", load: () => import('src/app/pages/api-tools/tools/configuration-manager/configuration-manager.component') },
    { id: 'DtHostMonitoringMode', group: "apitools", load: () => import('src/app/pages/api-tools/tools/monitoring-mode/monitoring-mode.component') },
    { id: 'DtBatchHostConfig',    group: "apitools", load: () => import('src/app/pages/api-tools/tools/batch-host-config/batch-host-config.component') },

    // Api Reports
//     { id: 'DtDavisScape', group: "apitools", load: () => import('src/app/pages/api-tools/3d/davis-scape/davis-scape.component') },
    { id: 'DtGlobeCityRum', group: "apitools", load: () => import('src/app/pages/api-tools/3d/globe-city-rum/globe-city-rum.component') },
    { id: 'DtEnvironmentDiff', group: "apitools", load: () => import('src/app/pages/api-tools/tools/environment-diff/environment-diff.component') },
//     { id: 'DtRumValidator', group: "apitools", load: () => import('src/app/pages/validators/rum-validator/rum-validator.component') },
    { id: 'DtAuditLogs', group: "apitools", load: () => import('src/app/pages/api-tools/reports/audit-logs/audit-logs.component') },
    { id: 'DtDDUUsage', group: "apitools", load: () => import('src/app/pages/api-tools/reports/ddu-usage/ddu-usage.component') },
    { id: 'DtEntityExport', group: "apitools", load: () => import('src/app/pages/api-tools/reports/entity-export/entity-export.component') },
    { id: 'DtUSQLExport', group: "apitools", load: () => import('src/app/pages/api-tools/reports/usql-export/usql-export.component') },
    { id: 'DtLicenseBreakdown', group: "apitools", load: () => import('src/app/pages/api-tools/reports/license-breakdown/license-breakdown.component') },
    { id: 'DtManagementZones', group: "apitools", load: () => import('src/app/pages/api-tools/reports/management-zones/management-zones.component') },
    { id: 'DtNetworkZones', group: "apitools", load: () => import('src/app/pages/api-tools/reports/network-zones/network-zones.component') },
    { id: 'DtOneAgentVersions', group: "apitools", load: () => import('src/app/pages/api-tools/reports/one-agent-versions/one-agent-versions.component') },
    { id: 'DtTagMapper', group: "apitools", load: () => import('src/app/pages/api-tools/reports/tag-mapper/tag-mapper.component') },
    { id: 'DtTagCompliance', group: "apitools", load: () => import('src/app/pages/api-tools/reports/tag-compliance/untagged-entities.component') },
];

