/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';

export interface Footprint_v1 extends DTO, DTOMethods<Footprint_v1> {
    technology: string;
    costcenter: DTORef;
    observabilityRequirements: DTORef[];
    oiiScore: number;
    logicalEnvironments: DTORef[];
    resolvedUsers: Attachment[];
}
export const Footprint_v1: DTOMetaData & DTOClassMethods<Footprint_v1> = {
    _label:'Deployment Footprints',
    _dto: 'Footprint_v1',
    _type: 'footprint',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'IDX (External Identifier)', prop: 'idx', hint: 'ID of this footprint', type: 'string', fixed: true, source: null, values: undefined },
        { label: 'Technology', prop: 'technology', hint: 'Text (max 256 chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Costcenter', prop: 'costcenter', hint: 'Costcenter', type: 'dto', fixed: true, source: '/api/eob/v1.0/costcenter/v1.0', values: undefined },
        { label: 'Observability Score', prop: 'oiiScore', hidden: true, hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
     ],
    _related: [
        { prop: 'observabilityRequirements', type: 'observability-requirements', class: 'ObservabilityRequirements_v1', label: 'Observability Requirements' },
        { prop: 'logicalEnvironments', type: 'logical-environment', class: 'LogicalEnvironment_v1', label: 'Logical Environments' },
    //    { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'UsersWithFullyResolvedAccessLevel' },
     ],
    _endpoint: '/api/eob/v1.0/footprint/v1.0',
    _childrenAt: 'logicalEnvironments',
    _parentDTONames: 'Application_v1,Platform_v1',
    _icon: './assets/dtos/footprint.svg',
    _docLink: '/wiki/elevate/Assets/footprint/',
    endpointAPI: new BoundEndpointAPI<Footprint_v1>('Footprint_v1', DTO),
    from: (obj: any): Footprint_v1 => {
        return Object.setPrototypeOf(obj, Footprint_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Footprint_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Footprint_v1.from(a) as Footprint_v1)
            : Footprint_v1.from(res)
    )}
}
