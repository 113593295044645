import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'src/app/types/menu';
import { UserService } from '../../services/user.service';
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UpdateService } from 'src/app/services/update.service';
import { MenuDirective } from 'src/app/directives/menu.directive';
import { MenuItem as ContextMenuItem } from 'src/app/types/popups';
import { NavMenuService } from 'src/app/services/nav-menu.service';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: true,
    imports: [
        MatTooltipModule,
        MatIconModule,
        MenuDirective,
        AsyncPipe
    ]
})
export class MenuComponent {

    @Input() isMobile = false;

    public persona = "Persona";
    public personaShortForm = "";
    collapsed = false;
    showAdvancedMenu = false;
    allowAdvancedMenu = false;

    headless = location.href.includes("headless=true");

    usefulLinks: ContextMenuItem[] = [
        { label: "Setup" },
        { label: "Intro" },
        "separator",
        { label: "Docs" },
        { label: "Release Notes" },
        // "separator",
        // { label: "News" },
        // { label: "Docs" },
    ]

    supportLinks: ContextMenuItem[] = [
        { label: "Documentation", link: "https://dt-url.net/docs", linkTarget: "_blank" },
        // { label: "Community" }, // stackoverflow?
        { label: "University", link: "https://dt-url.net/0j2214q", linkTarget: "_blank" },

        // "separator",
        // { label: "Elevate API" },
        // { label: "Support Ticket" },
    ]

    profileLinks: ContextMenuItem[] = [
        // {
        //     label: "Personalize Menu",
        //     action: () => {

        //     }
        // },
        // "separator",
        {
            label: "Preferences",
            icon: 'settings',
            link: "/#/UserPreferences"
        },
        {
            label: "Setup Wizard",
            icon: 'face',
            action: () => this.user.openPersonaSelection().then(() => {
                this.persona = this.user.getPersonaLabel();
                this.personaShortForm = this.user.getCurrentUserPersonaLabel();
            })
        },
        // {
        //     label: "User Settings",
        //     link: "#/users?trail=user.{current.user.id}"
        // },
        {
            label: "Appearance",
            icon: 'invert_colors',
            children: [
                // {
                //     label: "Browser Theme",
                //     action: () => {

                //     }
                // },
                // "separator",
                {
                    // label: "Light",
                    labelTemplate: () => `${this.theme.theme == "light" ? '⏺' : '\u00A0\u00A0\u00A0'} Light`,
                    action: () => {
                        this.theme.setLightTheme();
                    }
                },
                {
                    // label: "Dark",
                    labelTemplate: () => `${this.theme.theme == "dark" ? '⏺' : '\u00A0\u00A0\u00A0\u00A0'} Dark`,
                    action: () => {
                        this.theme.setDarkTheme();
                    }
                }
            ]
        },
        { label: "Terms of Use", link: "/#/Terms", icon: "policy" },
        { label: "Account Management", link: "https://dt-url.net/myaccount", linkTarget: "_blank", icon: "manage_accounts" },
        "separator",
        { label: "Log out", icon: 'logout', link: "/api/logout?ngsw-bypass=true" },
        {
            labelTemplate: () =>
                "Version " + this.updateService.gatewayVersion + "_" + this.updateService.packageVersion,
            icon: "rocket_launch"
        },
    ]

    constructor(
        public readonly user: UserService,
        public readonly updateService: UpdateService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly theme: ThemeLoaderService,
        private readonly dialog: DialogService,
        public readonly navMenu: NavMenuService
    ) {
        user.favorites$.subscribe(f => {
            this.changeDetector.markForCheck();
            // this.changeDetector.detectChanges();
        })

        this.personaShortForm = this.user.getCurrentUserPersonaLabel();

        // When the navigation menu gets a new menu, we update here.
        navMenu.navMenu$.subscribe(menuItems => {
            if (!menuItems) return;

            // It may be possible that we're still looking at the user object as it was returned
            // to us before the user switched tenants. And if so, we're using the wrong persona.
            this.persona = this.user.getPersonaLabel();
        });

        user.preferences$.subscribe(pref => {
            this.allowAdvancedMenu = pref.general?.menumode != "beginner";
            this.showAdvancedMenu  = pref.general?.menumode == "advanced";
        });
    }

    ngOnInit() {
        if (
            (
                typeof this.user.value.persona != "string" ||
                this.user.value.persona.length == 0 ||
                !this.user.userCompletedSetup()
            ) &&
            !this.headless // Do not open when generating reports.
        ) {
            this.user.openPersonaSelection().then(() => {
                this.persona = this.user.getPersonaLabel();
                this.personaShortForm = this.user.getCurrentUserPersonaLabel();
            });
        }
    }

    toggleMenu() {
        this.user.updatePreference(
            "general",
            "menumode",
            this.showAdvancedMenu ? "intermediate" : "advanced"
        );
    }


    openAskElevate() {
        // Hide the dialog if it's already present
        if (document.querySelector('.dialog-askElevate')) {
            window['__askElevate']?.dialog?.close();
            return;
        };

        const lastX = localStorage["es-openai-dialog-x"] || '0px';
        const lastY = localStorage["es-openai-dialog-y"] || '0px';

        let pos;
        if (lastX != '0px') {
            pos = {
                left: lastX == '0px' ? null : lastX,
                top: lastY == '0px' ? null : lastY
            };
        }
        else {
            pos = {
                left: this.collapsed ? '56px' : this.isMobile ? '300px' : '200px',
                top: '46px'
            }
        }

        this.dialog.open("AskElevate", {
            disableClose: true,
            restoreFocus: true,
            hasBackdrop: false,
            panelClass: ["dialog-askElevate", "dialog-draggable"],
            position: pos,
            isModal: false
        })
    }

    openFeedback() {
        this.dialog.open('nav-feedback', { isDark: true })
    }
}
