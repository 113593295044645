<!-- 200px expanded; 56px collapsed. 300px when expanded on mobile -->
<div class="menu" [class.collapsed]="collapsed">

    <!-- @if (user.isDynatraceUser) {
        <div class="item ask-elevate" (click)="openAskElevate()" [matTooltip]="collapsed ? 'Ask Elevate' : null">
            <span class="icon">
                <mat-icon>tips_and_updates</mat-icon>
            </span>
            <span class="label">Ask Elevate</span>
        </div>
    } -->

    <!-- <div class="item"[matTooltip]="collapsed ? 'Sitemap' : null">
        <span class="icon">
            <mat-icon>language</mat-icon>
        </span>
        <span class="label">Sitemap</span>
    </div> -->

    <!-- --------- -->
    <hr/>

    @if (allowAdvancedMenu) {
    <div class="item mode-toggle"
        (click)="toggleMenu()">
        <span class="icon">
            <mat-icon
                [fontIcon]="showAdvancedMenu ? 'toggle_on' : 'toggle_off'"
                [style.color]="showAdvancedMenu ? '#2196f3' : null" />
        </span>
        <span class="label">
            @if (showAdvancedMenu) {
                <span>Power user menu</span>
            }
            @else {
                <!-- TODO: Show persona name here -->
                <span>{{persona || 'Persona'}} menu</span>
            }
        </span>
        <!--mat-slide-toggle style="pointer-events: none;" [checked]="showAdvancedMenu">&nbsp;</mat-slide-toggle -->
    </div>
    }

    <hr />
    <!-- Create a side-by-side pane that can transition between views -->
    <div
        class="menu-switcher"
        [style.transform]="showAdvancedMenu ? 'translateX(calc(-50% - 8px))' : null"
    >
        <div style="width: 50%; padding-right: 8px">
            @for (rootitem of (navMenu.personaMenu$ | async); track rootitem) {
                @if (rootitem['persona'] == personaShortForm) {
                    @if (rootitem.url) {
                        <a class="item"
                            [class.selected]="rootitem.state?.includes('selected') ? 'red' : null"
                            [matTooltip]="collapsed ? rootitem.label : null"
                            matTooltipPosition="right"
                            [href]="rootitem.url ? rootitem.url : null"
                        >
                            <span class="icon">
                                <mat-icon
                                    [fontIcon]="rootitem.icon"
                                    [style.color]="rootitem.background"
                                />
                            </span>
                            <span class="label">{{rootitem.label}}</span>
                        </a>
                    }
                    @else {
                        <div class="item"
                            [class.selected]="rootitem.state?.includes('selected') ? 'red' : null"
                            [matTooltip]="collapsed ? rootitem.label : null"
                            matTooltipPosition="right"
                            [ngx-menu]="rootitem['children']"
                            [ngx-menu-config]="{ position: 'right', alignment: 'start', customClass: ['dt-menu'] }"
                        >
                            <span class="icon">
                                <mat-icon
                                    [fontIcon]="rootitem.icon"
                                    [style.color]="rootitem.background"
                                />
                            </span>
                            <span class="label">{{rootitem.label}}</span>
                        </div>
                    }
                }
            }
        </div>

        <!-- TODO: Only render if user can remove persona filter -->
        <div style="width: 50%; padding-right: 8px">
            @for (rootitem of (navMenu.navMenu$ | async); track rootitem) {
                @if (rootitem.parent == 0) {
                    @if (rootitem.url) {
                        <a class="item"
                            [class.selected]="rootitem.state?.includes('selected') ? 'red' : null"
                            [matTooltip]="collapsed ? rootitem.label : null"
                            matTooltipPosition="right"
                            [href]="rootitem.url ? rootitem.url : null"
                        >
                            <span class="icon">
                                <mat-icon
                                    [fontIcon]="rootitem.icon"
                                    [style.color]="rootitem.background"
                                />
                            </span>
                            <span class="label">{{rootitem.label}}</span>
                        </a>
                    }
                    @else {
                        <div class="item"
                            [class.selected]="rootitem.state?.includes('selected') ? 'red' : null"
                            [matTooltip]="collapsed ? rootitem.label : null"
                            matTooltipPosition="right"
                            [ngx-menu]="rootitem['children']"
                            [ngx-menu-config]="{ position: 'right', alignment: 'start', customClass: ['dt-menu'] }"
                        >
                            <span class="icon">
                                <mat-icon
                                    [fontIcon]="rootitem.icon"
                                    [style.color]="rootitem.background"
                                />
                            </span>
                            <span class="label">{{rootitem.label}}</span>
                        </div>
                    }
                }
            }
        </div>
    </div>


    <span style="flex: 1"></span>
<!--
    <div class="item"
        [matTooltip]="!collapsed ? null : 'Power Mode'"
    >
        <span class="icon">
            <mat-icon
                (click)="showAdvancedMenu = !showAdvancedMenu"
                [fontIcon]="showAdvancedMenu ? 'hub' : 'center_focus_weak'"
                [style.color]="showAdvancedMenu ? '#2196f3' : null"
            />
        </span>
        <span class="label" style="width: 100px;" (click)="showAdvancedMenu = !showAdvancedMenu">
            <span *ngIf="showAdvancedMenu">Power user</span>
            <span *ngIf="!showAdvancedMenu">Persona</span>
        </span>
        <mat-slide-toggle [checked]="showAdvancedMenu">&nbsp;</mat-slide-toggle>
    </div>

    --------- -->
    <hr/>

    <div class="menu-boltons">
        <div class="item collapse-button"
            [matTooltip]="collapsed ? 'Expand Dock' : null"
            matTooltipPosition="right"
            (click)="collapsed = !collapsed"
        >
            <!-- Overlay search functionality -->

            <span class="icon">
                <mat-icon [fontIcon]="collapsed ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left'"></mat-icon>
            </span>
            <span class="label">Collapse</span>
        </div>

        <div class="item feedback-button"
            [matTooltip]="collapsed ? 'Feedback' : null"
            matTooltipPosition="right"
            (click)="openFeedback()"
        >
            <!-- Overlay search functionality -->
            <span class="icon">
                <mat-icon>thumb_up_alt</mat-icon>
            </span>
            <span class="label">Feedback</span>
        </div>

        <div class="item support-button"
            [matTooltip]="collapsed ? 'Support' : null"
            matTooltipPosition="right"
            [ngx-menu]="supportLinks"
            [ngx-menu-config]="{ position: 'right', alignment: 'end', customClass: ['dt-menu']}"
            >
            <!-- Overlay search functionality -->
            <span class="icon">
                <mat-icon>support</mat-icon>
            </span>
            <span class="label">Support</span>
        </div>

        <div class="item user-profile"
            [matTooltip]="collapsed ? user.name : null"
            matTooltipPosition="right"
            [ngx-menu]="profileLinks"
            [ngx-menu-config]="{ position: 'right', alignment: 'end', customClass: ['dt-menu']}"
        >
            <!-- Overlay search functionality -->
            <span class="icon">
                <mat-icon>account_circle</mat-icon>
            </span>
            <span class="label">{{user.name}}</span>
        </div>
    </div>
</div>
