import { DTO } from "../dto";

export const PMORegistrations = DTO;

import { Event_v1 } from "./event-v1";
PMORegistrations.register(Event_v1);

import { Project_v1 } from "./project-v1";
PMORegistrations.register(Project_v1);

import { Goal_v1 } from "./goal-v1";
PMORegistrations.register(Goal_v1);

import { CoverageGoal_v1 } from "./coverage-goal-v1";
PMORegistrations.register(CoverageGoal_v1);

import { Objective_v1 } from "./objective-v1";
PMORegistrations.register(Objective_v1);

import { CoverageObjective_v1 } from "./coverage-objective-v1";
PMORegistrations.register(CoverageObjective_v1);

import { Task_v1 } from "./task-v1";
PMORegistrations.register(Task_v1);

import { Issue_v1 } from "./issue-v1";
PMORegistrations.register(Issue_v1);

import { Risk_v1 } from "./risk-v1";
PMORegistrations.register(Risk_v1);

import { Meeting_v1 } from "./meeting-v1";
PMORegistrations.register(Meeting_v1);

import { DynatraceEvent_v1 } from "./dynatrace-event-v1";
PMORegistrations.register(DynatraceEvent_v1);
