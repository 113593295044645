/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';

export interface Ratecard_v1 extends DTO, DTOMethods<Ratecard_v1> {
    isActive: boolean;
    rateType: string;
    startDate: Date;
    endDate: Date;
    fullstackDailyUnitCount: number;
    fullstackDailyRate: number;
    infraDailyUnitCount: number;
    infraDailyUnitRate: number;
    appSecDailyUnitCount: number;
    appSecDailyUnitRate: number;
    dduDailyUnitCount: number;
    dduDailyUnitRate: number;
    demDailyUnitCount: number;
    demDailyUnitRate: number;
    zosDailyUnitCount: number;
    zosDailyUnitRate: number;
    grailuDailyUnitCount: number;
    grailuDailyUnitRate: number;
    flatDaily: number;
    dynatraceEnvironment: DTORef;
    resolvedUsers: Attachment[];
}
export const Ratecard_v1: DTOMetaData & DTOClassMethods<Ratecard_v1> = {
    _label:'Observability Rate Cards',
    _dto: 'Ratecard_v1',
    _type: 'ratecard',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Is active', prop: 'isActive', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined },
        { label: 'Rate type', prop: 'rateType', hint: 'Text (max 64k chars)', type: 'enum', fixed: true, source: undefined, values: ["flat", "proportional"] },
        { label: 'Start date', prop: 'startDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined },
        { label: 'End date', prop: 'endDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined },
        { label: 'Fullstack daily unit count', prop: 'fullstackDailyUnitCount', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Fullstack daily rate', prop: 'fullstackDailyRate', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Infra daily unit count', prop: 'infraDailyUnitCount', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Infra daily unit rate', prop: 'infraDailyUnitRate', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'App sec daily unit count', prop: 'appSecDailyUnitCount', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'App sec daily unit rate', prop: 'appSecDailyUnitRate', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Ddu daily unit count', prop: 'dduDailyUnitCount', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Ddu daily unit rate', prop: 'dduDailyUnitRate', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Dem daily unit count', prop: 'demDailyUnitCount', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Dem daily unit rate', prop: 'demDailyUnitRate', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Zos daily unit count', prop: 'zosDailyUnitCount', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Zos daily unit rate', prop: 'zosDailyUnitRate', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Grailu daily unit count', prop: 'grailuDailyUnitCount', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Grailu daily unit rate', prop: 'grailuDailyUnitRate', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Flat daily', prop: 'flatDaily', hint: 'Decimal value (-10^255+1 .. 10^255-1, 2 decimals)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Dynatrace environment', prop: 'dynatraceEnvironment', hint: 'Dynatrace environment', type: 'dto', fixed: true, source: '/api/eob/v1.0/dynatrace-environment/v1.0', values: undefined },
     ],
    _related: [
        { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'UsersWithFullyResolvedAccessLevel' },
     ],
    _endpoint: '/api/eob/v1.0/ratecard/v1.0',
    _docLink: '/wiki/elevate/Assets/ratecard/',
    _icon: './assets/dtos/ratecard.svg',

    _parentDTONames: 'Rategroup_v1',


    endpointAPI: new BoundEndpointAPI<Ratecard_v1>('Ratecard_v1', DTO),
    from: (obj: any): Ratecard_v1 => {
        return Object.setPrototypeOf(obj, Ratecard_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Ratecard_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Ratecard_v1.from(a) as Ratecard_v1)
            : Ratecard_v1.from(res)
    )}
}
