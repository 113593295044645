import { isDevMode } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommandPaletteService } from '@dotglitch/ngx-common';
import { LazyLoaderService } from 'src/app/components/lazy-loader/lazy-loader.service';
import { EditObjectGenericComponent } from 'src/app/pages/@dialogs/generic-properties/generic-properties.component';

import { RootComponent } from 'src/app/root.component';
import { DialogService } from 'src/app/services/dialog.service';
import { Fetch } from 'src/app/services/fetch.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ThemeLoaderService } from 'src/app/services/theme-loader.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UpdateService } from 'src/app/services/update.service';
import { UserService } from 'src/app/services/user.service';

export class GlobalCommands {
    constructor(
        private readonly root: RootComponent,
        private readonly toaster: ToasterService,
        private readonly fetch: Fetch,
        private readonly themeLoader: ThemeLoaderService,
        private readonly user: UserService,
        private readonly dialog: DialogService,
        private readonly matDialog: MatDialog,
        private readonly tenant: TenantService,
        private readonly notification: NotificationService,
        private readonly updateService: UpdateService,
        private readonly lazyLoader: LazyLoaderService,
        public  readonly commandPalette: CommandPaletteService
    ) {
        this.registerCommands();
    }

    private registerCommands() {
        const { commandPalette } = this;
        commandPalette.initialize({
            keybind: "ctrl+p"
        });

        commandPalette.attachElementCommands([
            isDevMode() ? { label: "Debug", shortcutKey: "pause", action: () => {
                debugger;
            }} : { visibleInList: false },
            // { label: "Help", shortcutKey: "F1", action: () => {

            // }},
            { label: "Logout", action: this.user.logout },
            {
                label: "Preferences: Open settings", action: () => {
                    window.location.hash = `/UserPreferences`;
                }
            },
            { label: "Theme: Dark", action: () => this.themeLoader.setDarkTheme() },
            { label: "Theme: Light", action: () => this.themeLoader.setLightTheme() },
            // { label: "Preferences: Open settings (JSON)", action: async () => {
            //     const [json, component] = await Promise.all([
            //         fetch.get<object>("/api/eda/v1.0/data?name=preferences_list&category=%"),
            //         import("@dotglitch/ngx-common").then(m => m.VscodeComponent)
            //     ]);



            //     const d = this.matDialog.open(component, {
            //         width: "90vw",
            //         height: "90vh"
            //     });
            //     d.componentInstance.language = "json";
            //     d.componentInstance.code = JSON.stringify(json, null, 4);
            // }},
            {
                label: "Show Elevate tour",
                shortcutKey: 'F1',
                keywords: ["demo", "tour"],
                action: () => this.root.startTour()
            }
        ])

        if (this.user.isPowerUser) {
            commandPalette.attachElementCommands([
                {
                    label: "Save and close current dialog",
                    shortcutKey: 'ctrl+s',
                    action: e => {
                        const activeDialogs = [
                            ...(window['elevate_EditObjectGenericComponent']?.instances as EditObjectGenericComponent[] ?? [])
                        ];

                        // Save and close the last opened dialog.
                        Promise.all(activeDialogs.slice(-1).map(async d => {
                            await d.onSave();
                            d.onClose();
                        }));
                    }
                },
                {
                    label: "Save and close all dialogs",
                    shortcutKey: 'ctrl+shift+s',
                    action: e => {
                        const activeDialogs = [
                            ...(window['elevate_EditObjectGenericComponent']?.instances as EditObjectGenericComponent[] ?? [])
                        ];

                        // Save and close all dialogs.
                        Promise.all(activeDialogs.map(async d => {
                            await d.onSave();
                            d.onClose();
                        }));
                    }
                }
            ])
        }
    }
}
