import { registerLicense } from '@syncfusion/ej2-base';
import { importProvidersFrom } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';

import { RootComponent } from './app/root.component';
import { NotFoundComponent } from './app/pages/@framework/not-found/not-found.component';
import { ErrorComponent } from 'src/app/pages/@framework/error/error.component';
import { ApiTools } from 'src/app/pages/api-tools/apitool.registry';
import { DashboardTileRegistry } from 'src/app/pages/general/dashboard/dashboard.registry';
import { RegisteredComponents } from 'src/app/component.registry';
import { LoaderInterceptor } from './app/utils/http.interceptor';
import { LazyLoaderModule } from 'src/app/components/lazy-loader/lazy-loader.module';
import { ProgressDistractorComponent } from 'src/app/components/progress-distractor/progress-distractor.component';

registerLicense("Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXtcdXRcRmFdWUNzWUA=");

bootstrapApplication(RootComponent, {
    providers: window['ng_global_providers'] = [
        importProvidersFrom(
            BrowserModule,
            MatDialogModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatSnackBarModule,
            LazyLoaderModule.forRoot({
                entries: [
                    ...RegisteredComponents,
                    ...DashboardTileRegistry,
                    ...ApiTools
                ] as any,
                loaderDistractorComponent: ProgressDistractorComponent,
                errorComponent: ErrorComponent,
                notFoundComponent: NotFoundComponent
            }),
            ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: !location.host.includes('localhost'),
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000'
            })
        ),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
    .catch(err => console.error(err));
