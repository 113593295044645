import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from '../../services/user.service';
import { TenantService } from 'src/app/services/tenant.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterService } from 'src/app/services/router.service';

export type Tenant = {
    es_tenant_name: string
    account_name: string
}

@Component({
    selector: 'app-tenantselector',
    templateUrl: './tenantselector.component.html',
    styleUrls: ['./tenantselector.component.scss'],
    standalone: true,
    imports: [
        MatProgressBarModule
    ]
})
export class TenantSelectorComponent implements OnInit {
    @ViewChild("autocomplete") autocomplete: ElementRef;

    readonly clientItemHeight = 38;

    loadingTenant = false;
    demoTenants: Tenant[] = [];
    filteredTenants: Tenant[] = [];


    constructor(
        public readonly user: UserService,
        public readonly tenant: TenantService,
        private readonly toaster: ToasterService,
        private readonly router: RouterService
    ) {
        window['tenantselector'] = this;
    }

    ngOnInit(): void {
        this.demoTenants = this.user.tenants.filter(e => e.account_name == "Demo");
        this.filteredTenants = this.user.tenants as any;
    }

    isAutocompleteVisible = false;
    tenantFilter: string = '';
    filterTenants(query: string) {
        const filterValue = query.toLowerCase().trim();

        this.filteredTenants = filterValue.length == 0
            ? this.user.tenants
            : this.user.tenants.filter(tenant =>
                (tenant.es_tenant_name + tenant.account_name).toLowerCase()
                    .includes(filterValue)
            );
    }

    // Handle 'Enter' keypress to select the first filtered tenant.
    filterTenantKeyPress(evt: KeyboardEvent) {
        if ((evt.code == 'Enter' || evt.code == 'Return') && !!this.filteredTenants[0]) {
            this.onClientSelect(this.filteredTenants[0]);
            this.tenantFilter = '';
            this.filterTenants('');
            this.isAutocompleteVisible = false;
        }
    }

    onClose() {
        this.isAutocompleteVisible = false;
        this.tenantFilter = '';
        this.filterTenants('');
    }

    isPresenting = false;
    presentedTenant: Tenant;
    onPresentationModeClick() {
        this.isPresenting = !this.isPresenting; // toggle state
        this.presentedTenant = this.user.tenants.find(e => e.es_tenant_name == this.tenant.value.es_tenant_name && e.account_name != "Demo");

        this.isAutocompleteVisible = !this.isPresenting;
        if (!this.isPresenting) {
            this.autocomplete.nativeElement.focus();
        }
    }

    selectTenant(tenant: string) {
        tenant = tenant?.toLowerCase() || this.tenant.value.es_tenant_name.toLowerCase();
        const tenants = this.demoTenants.concat(this.filteredTenants);
        return this.onClientSelect(tenants.find(t => t.es_tenant_name.toLowerCase() == tenant))
    }

    // User selected a tenant
    onClientSelect(tenant: Tenant) {
        // Skip if there is no tenant or if the tenant is not changed
        if (!tenant || tenant.es_tenant_name == this.tenant.value.es_tenant_name) return;

        if (!this.checkTenantAccess(tenant)) {
            this.toaster.warn("Failed to select client.", `Tenant ${tenant.es_tenant_name} was not found. Please validate your accessing the correct resource and you have sufficient rights to view it.`);
            return;
        }

        this.loadingTenant = true;

        this.tenantFilter = '';

        // Load the landing
        // location.hash.split('?')[0]
        this.router.loadRouteString("#/Landing");

        this.user.loadUserProfile(tenant.es_tenant_name).then(p => {
            if (!this.checkTenantAccess(tenant)) {
                this.toaster.warn("Selected invalid tenant.", `Tenant ${tenant.es_tenant_name} is not present. Please validate your accessing the correct resource and you have sufficient rights to view it.`);
                return;
            }
        })
        .finally(() => {
            this.loadingTenant = false;
        })

        this.onClose();
    }

    private checkTenantAccess(tenant: Tenant) {
        // Warn if the tenant doesn't exist in the user's account list.
        if (!this.user.tenants.find(t => t.es_tenant_name == tenant.es_tenant_name)) {
            return false;
        }
        return true
    }
}
