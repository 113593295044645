export const DashboardTileRegistry = [
    // Dashboard widgets
    { group: "tile", id: 'tile-description', load: () => import('src/app/pages/general/dashboard/@tiles/description/description.component') },
    { group: "tile", id: 'tile-properties', load: () => import('src/app/pages/general/dashboard/@tiles/properties/properties.component') },
    { group: "tile", id: 'tile-userdetails', load: () => import('src/app/pages/general/dashboard/@tiles/widget-userdetails/widget-userdetails.component') },
    { group: "tile", id: 'tile-userphoto', load: () => import('src/app/pages/general/dashboard/@tiles/user-photo/user-photo.component') },
    { group: "tile", id: 'tile-mapbubble', load: () => import('src/app/pages/general/dashboard/@tiles/widget-mapbubble/widget-mapbubble.component') },
    { group: "tile", id: 'tile-piechart', load: () => import('src/app/pages/general/dashboard/@tiles/widget-piechart/widget-piechart.component') },
    { group: "tile", id: 'tile-areachart', load: () => import('src/app/pages/general/dashboard/@tiles/widget-areachart/widget-areachart.component') },
    { group: "tile", id: 'tile-badge', load: () => import('src/app/pages/general/dashboard/@tiles/badge/badge.component') },
    { group: "tile", id: 'tile-stacked-horizontal-bar', load: () => import('src/app/pages/general/dashboard/@tiles/widget-stackedHorizontalBar/widget-stackedHorizontalBar.component') },
    { group: "tile", id: 'tile-status', load: () => import('src/app/pages/general/dashboard/@tiles/widget-status/widget-status.component') },
    { group: "tile", id: 'tile-stats', load: () => import('src/app/pages/general/dashboard/@tiles/widget-stats/widget-stats.component') },
    { group: "tile", id: 'tile-gauge', load: () => import('src/app/pages/general/dashboard/@tiles/widget-gauge/widget-gauge.component') },
];
