/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { CloudProvider } from 'src/dto/eob/cloudproviders';
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI
} from '../dto';

export interface ProviderType_v1 extends DTO, DTOMethods<ProviderType_v1> {
    providerType: CloudProvider;
    providers: DTORef[];
}
export const ProviderType_v1: DTOMetaData & DTOClassMethods<ProviderType_v1> = {
    _label: 'Provider Types',
    _dto: 'ProviderType_v1',
    _type: 'provider-type',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Provider Type', prop: 'providerType', hint: 'Text (max 64k chars)', type: 'enum', fixed: true, required: true, source: undefined, values: ["native", "azure", "gcp", "aws", "other"] },
    ],
    _related: [
        { prop: 'providers', type: 'provider', class: 'Provider_v1', label: 'Providers' }
    ],
    _endpoint: '/api/eob/v1.0/provider-types/v1.0',
    _childrenAt: 'providers',
    _docLink: '/wiki/elevate/Assets/provider/',
    _icon: './assets/dtos/provider.svg',

    endpointAPI: new BoundEndpointAPI<ProviderType_v1>('Provider_v1', DTO),
    from: (obj: any): ProviderType_v1 => {
        return Object.setPrototypeOf(obj, ProviderType_v1._prototype);
    },
    select: (p: any = ''): any => {
        return ProviderType_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => ProviderType_v1.from(a) as ProviderType_v1)
            : ProviderType_v1.from(res)
        )
    }
}
