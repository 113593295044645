/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';
import { Ratecard_v1 } from './ratecard-v1';

export interface Rategroup_v1 extends DTO, DTOMethods<Rategroup_v1> {
    cards: Ratecard_v1[];
    resolvedUsers: Attachment[];
}
export const Rategroup_v1: DTOMetaData & DTOClassMethods<Rategroup_v1> = {
    _label:'Rate Groups',
    _dto: 'Rategroup_v1',
    _type: 'rategroup',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
     ],
    _related: [
        { prop: 'cards', type: 'ratecard', class: 'Ratecard_v1', label: 'Observability Rate Card' },
   //     { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'UsersWithFullyResolvedAccessLevel' },
     ],
    _endpoint: '/api/eob/v1.0/rategroup/v1.0',
    _docLink: '/wiki/elevate/Assets/rategroup/',
    _icon: './assets/dtos/rategroup.svg',
    //_color: '#123000',

    endpointAPI: new BoundEndpointAPI<Rategroup_v1>('Rategroup_v1', DTO),
    from: (obj: any): Rategroup_v1 => {
        return Object.setPrototypeOf(obj, Rategroup_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Rategroup_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Rategroup_v1.from(a) as Rategroup_v1)
            : Rategroup_v1.from(res)
    )}
}
