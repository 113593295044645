export type ClusterType = number;
export const ClusterType = {
    Dynatrace_SaaS: 1,
    Dynatrace_Dedicated_SaaS: 2,
    Dynatrace_Managed_PHA_SaaS: 6,
    Dynatrace_Managed: 7,
    Unknown_Cluster_Type: 9,

    1: "Dynatrace SaaS",
    2: "Dynatrace Dedicated SaaS",
    6: "Dynatrace Managed PHA SaaS",
    7: "Dynatrace Managed",
    9: "Unknown Cluster Type",

    get values() { return Object.keys(this).filter(key => parseInt(key) != key as any && key === key.toUpperCase()) }
}
export const clusterTypes = [
    { id: ClusterType.Dynatrace_SaaS, label: 'Dynatrace SaaS', icon: '' },
    { id: ClusterType.Dynatrace_Dedicated_SaaS, label: 'Dynatrace Dedicated SaaS', icon: '' },
    { id: ClusterType.Dynatrace_Managed_PHA_SaaS, label: 'Dynatrace Managed PHA SaaS', icon: '' },
    { id: ClusterType.Dynatrace_Managed, label: 'Dynatrace Managed', icon: '' },
    { id: ClusterType.Unknown_Cluster_Type, label: 'Unknown Cluster Type', icon: '' }
];
