/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { clusterTypes } from 'src/dto/eob/clustertypes';
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI
} from '../dto';

export interface DynatraceCluster_v1 extends DTO, DTOMethods<DynatraceCluster_v1> {
    url: string;
    isProduction: boolean;
    clusterType: string;
    environments: DTORef[];
}
export const DynatraceCluster_v1: DTOMetaData & DTOClassMethods<DynatraceCluster_v1> = {
    _label: 'Dynatrace Clusters',
    _dto: 'DynatraceCluster_v1',
    _type: 'dynatrace-cluster',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Cluster type', prop: 'clusterType', hint: 'cluster type', type: 'enum', required: true, fixed: true, source: null, values: clusterTypes },
        { label: 'Url', prop: 'url', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Is production', prop: 'isProduction', hint: 'Yes/No', type: 'boolean', required: true, fixed: true, source: undefined, values: undefined },
    ],
    _related: [
        { prop: 'environments', type: 'dynatrace-environment', class: 'DynatraceEnvironment_v1', label: 'Dynatrace Environments' },
    ],
    _endpoint: '/api/eob/v1.0/dynatrace-cluster/v1.0',
    _docLink: '/wiki/elevate/Assets/dynatrace-cluster/',
    _icon: './assets/dtos/dynatrace-cluster.svg',

    endpointAPI: new BoundEndpointAPI<DynatraceCluster_v1>('DynatraceCluster_v1', DTO),
    from: (obj: any): DynatraceCluster_v1 => {
        return Object.setPrototypeOf(obj, DynatraceCluster_v1._prototype);
    },
    select: (p: any = ''): any => {
        return DynatraceCluster_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
                : Array.isArray(res) ? res.map(a => DynatraceCluster_v1.from(a) as DynatraceCluster_v1)
                    : DynatraceCluster_v1.from(res)
        )
    }
}
