/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI
} from '../dto';

export interface MetadataMapping_v1 extends DTO, DTOMethods<MetadataMapping_v1> {
    refId: number;
    refName: string;
    sequence: number;
    targetPropertyName: string;
    sourceEntityField: string;
    sourceEntityValue: string;
    valueExpression: string;
    valueOutcome: string;
}
export const MetadataMapping_v1: DTOMetaData & DTOClassMethods<MetadataMapping_v1> = {
    _label:'Unknown',
    _dto: 'MetadataMapping_v1',
    _type: 'metadata-mapping',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Ref id', prop: 'refId', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Ref name', prop: 'refName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Sequence', prop: 'sequence', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Target property name', prop: 'targetPropertyName', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Source entity field', prop: 'sourceEntityField', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Source entity value', prop: 'sourceEntityValue', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Value expression', prop: 'valueExpression', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Value outcome', prop: 'valueOutcome', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
     ],
    _related: [
     ],
    _endpoint: '/api//',


    endpointAPI: new BoundEndpointAPI<MetadataMapping_v1>('MetadataMapping_v1', DTO),
    from: (obj: any): MetadataMapping_v1 => {
        return Object.setPrototypeOf(obj, MetadataMapping_v1._prototype);
    },
    select: (p: any = ''): any => {
        return MetadataMapping_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => MetadataMapping_v1.from(a) as MetadataMapping_v1)
            : MetadataMapping_v1.from(res)
    )}
}
