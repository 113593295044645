/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';

export interface ObservabilityClaim_v1 extends DTO, DTOMethods<ObservabilityClaim_v1> {
    timeframe: number;
    managementZone: string;
    rateGroup: string;
    logicalEnvStrategy: string;
    application: DTORef;
    networkzone: DTORef;
    footprint: DTORef;
    logicalEnvironment: DTORef;
    dynatraceEnvironment: DTORef;
    metadataMappings: DTORef[];
    resolvedUsers: Attachment[];
}
export const ObservabilityClaim_v1: DTOMetaData & DTOClassMethods<ObservabilityClaim_v1> = {
    _label:'Unknown',
    _dto: 'ObservabilityClaim_v1',
    _type: 'observability-claim',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Timeframe', prop: 'timeframe', hint: 'Timespan', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Management zone', prop: 'managementZone', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Rate group', prop: 'rateGroup', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Logical env strategy', prop: 'logicalEnvStrategy', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined },
        { label: 'Application', prop: 'application', hint: 'D t o ref', type: 'dto', fixed: false, source: undefined, values: undefined },
        { label: 'Networkzone', prop: 'networkzone', hint: 'Networkzone', type: 'dto', fixed: true, source: '/networkzone/v1.0', values: undefined },
        { label: 'Footprint', prop: 'footprint', hint: 'Footprint', type: 'dto', fixed: true, source: '/footprint/v1.0', values: undefined },
        { label: 'Logical environment', prop: 'logicalEnvironment', hint: 'Logical environment', type: 'dto', fixed: true, source: '/logical-environment/v1.0', values: undefined },
        { label: 'Dynatrace environment', prop: 'dynatraceEnvironment', hint: 'Dynatrace environment', type: 'dto', fixed: true, source: '/dynatrace-environment/v1.0', values: undefined },
     ],
    _related: [
        { prop: 'metadataMappings', type: 'metadata-mapping', class: 'MetadataMapping_v1', label: 'Metadata mappings' },
        { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'UsersWithFullyResolvedAccessLevel' },
     ],
    _endpoint: '/api/eob/v1.0/observability-claim/v1.0',
    _docLink: '/wiki/elevate/Assets/observability-claim/',

    endpointAPI: new BoundEndpointAPI<ObservabilityClaim_v1>('ObservabilityClaim_v1', DTO),
    from: (obj: any): ObservabilityClaim_v1 => {
        return Object.setPrototypeOf(obj, ObservabilityClaim_v1._prototype);
    },
    select: (p: any = ''): any => {
        return ObservabilityClaim_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => ObservabilityClaim_v1.from(a) as ObservabilityClaim_v1)
            : ObservabilityClaim_v1.from(res)
    )}
}
