import { DTO } from "../dto";

export const EOMRegistrations = DTO;

import { Company_v1 } from "./company-v1";
EOMRegistrations.register(Company_v1);

import { Organization_v1 } from "./organization-v1";
EOMRegistrations.register(Organization_v1);

import { Group_v1 } from "./group-v1";
EOMRegistrations.register(Group_v1);

import { User_v1 } from "./user-v1";
EOMRegistrations.register(User_v1);

