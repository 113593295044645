import { DTO } from "../dto";

export const EOBRegistrations = DTO;

import { DynatraceCluster_v1 } from "./dynatrace-cluster-v1";
EOBRegistrations.register(DynatraceCluster_v1);

import { DynatraceEnvironment_v1 } from "./dynatrace-environment-v1";
EOBRegistrations.register(DynatraceEnvironment_v1);

import { ProviderType_v1 } from "./provider-type-v1";
EOBRegistrations.register(ProviderType_v1);

import { Provider_v1 } from "./provider-v1";
EOBRegistrations.register(Provider_v1);

import { Region_v1 } from "./region-v1";
EOBRegistrations.register(Region_v1);

import { Location_v1 } from "./location-v1";
EOBRegistrations.register(Location_v1);

import { Networkzone_v1 } from "./networkzone-v1";
EOBRegistrations.register(Networkzone_v1);

import { Portfolio_v1 } from "./portfolio-v1";
EOBRegistrations.register(Portfolio_v1);

import { Application_v1 } from "./application-v1";
EOBRegistrations.register(Application_v1);

import { Platform_v1 } from "./platform-v1";
EOBRegistrations.register(Platform_v1);

import { Footprint_v1 } from "./footprint-v1";
EOBRegistrations.register(Footprint_v1);

import { LogicalEnvironment_v1 } from "./logical-environment-v1";
EOBRegistrations.register(LogicalEnvironment_v1);

import { ObservabilityClaim_v1 } from "./observability-claim-v1";
EOBRegistrations.register(ObservabilityClaim_v1);

import { MetadataMapping_v1 } from "./metadata-mapping-v1";
EOBRegistrations.register(MetadataMapping_v1);

import { ObservableInventory_v1 } from "./observable-inventory-v1";
EOBRegistrations.register(ObservableInventory_v1);

import { ObservabilityAllocation_v1 } from "./observability-allocation-v1";
EOBRegistrations.register(ObservabilityAllocation_v1);

import { Ratecard_v1 } from "./ratecard-v1";
EOBRegistrations.register(Ratecard_v1);

import { Costcenter_v1 } from "./costcenter-v1";
EOBRegistrations.register(Costcenter_v1);

import { Rategroup_v1 } from "./rategroup-v1";
EOBRegistrations.register(Rategroup_v1);

