/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';

export interface Portfolio_v1 extends DTO, DTOMethods<Portfolio_v1> {
    resolvedUsers: Attachment[];
}
export const Portfolio_v1: DTOMetaData & DTOClassMethods<Portfolio_v1> = {
    _label:'Portfolios',
    _dto: 'Portfolio_v1',
    _type: 'portfolio',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
     ],
    _related: [
        { prop: 'resolvedUsers', type: 'unknown', class: 'Unknown', label: 'UsersWithFullyResolvedAccessLevel' },
     ],
    _endpoint: '/api//',
    _docLink: '/wiki/elevate/Assets/portfolio/',
    _icon: './assets/dtos/portfolio.svg',

    endpointAPI: new BoundEndpointAPI<Portfolio_v1>('Portfolio_v1', DTO),
    from: (obj: any): Portfolio_v1 => {
        return Object.setPrototypeOf(obj, Portfolio_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Portfolio_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Portfolio_v1.from(a) as Portfolio_v1)
            : Portfolio_v1.from(res)
    )}
}
