/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment
} from '../dto';

export interface LogicalEnvironment_v1 extends DTO, DTOMethods<LogicalEnvironment_v1> {
    isProduction: boolean;
    costcenter: DTORef;
    oiiScore: number;
    footprint: DTORef;
    resolvedUsers: Attachment[];
}
export const LogicalEnvironment_v1: DTOMetaData & DTOClassMethods<LogicalEnvironment_v1> = {
    _label:'Logical Environments',
    _dto: 'LogicalEnvironment_v1',
    _type: 'logical-environment',
    _colors: { primaryThemeColor: "#4a89d2", secondaryThemeColor: "#446c9b", tertiaryThemeColor: "#446c9b" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'IDX (External Identifier)', prop: 'idx', hint: 'ID of this environment', type: 'string', fixed: true, source: null, values: undefined },
        { label: 'Is production', prop: 'isProduction', required: true, hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined },
        { label: 'Costcenter', prop: 'costcenter', hint: 'Costcenter', type: 'dto', fixed: true, source: '/api/eob/v1.0/costcenter/v1.0', values: undefined },
        { label: 'Observability Score', hidden: true, prop: 'oiiScore', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined },
        { label: 'Footprint', hidden: true, prop: 'footprint', hint: 'Footprint', type: 'dto', fixed: true, source: '/api/eob/v1.0/footprint/v1.0', values: undefined },
     ],
    _related: [
        { prop: 'inventoryByProvider', type: 'provider', class: 'Provider_v1.Region_v1.Location_v1.Networkzone_v1.ObservableInventory_v1', label: 'Providers hosting assets in this logical environment' },
        { prop: 'servicesByProvider', type: 'observable-service', class: 'Provider_v1.Region_v1', label: 'Provider services leveraged by this logical environment' },
    ],
    _endpoint: '/api/eob/v1.0/logical-environment/v1.0',
    _parentDTONames: 'Footprint_v1',
    _icon: './assets/dtos/logical-environment.svg',
    _docLink: '/wiki/elevate/Assets/logical-environment/',
    endpointAPI: new BoundEndpointAPI<LogicalEnvironment_v1>('LogicalEnvironment_v1', DTO),
    from: (obj: any): LogicalEnvironment_v1 => {
        return Object.setPrototypeOf(obj, LogicalEnvironment_v1._prototype);
    },
    select: (p: any = ''): any => {
        return LogicalEnvironment_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => LogicalEnvironment_v1.from(a) as LogicalEnvironment_v1)
            : LogicalEnvironment_v1.from(res)
    )}
}
